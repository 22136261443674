/* General styles */

.banner-area1 {
    background-image: url("../../assets/img/gif/Gif.gif");
    background-size: cover;
    background-position: center;
    height: 60vh; /* Adjusted banner height for better proportion */
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
  z-index:0;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
  }
  
  .banner-area1:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }
  
  .banner-area1 h2 {
    font-size: 3rem;
    font-family: Cake sans;
    line-height: 1.2; /* Adjusted line height for better spacing */
    z-index: 2;
    color: #ff6f61; /* Updated color for banner h2 */
  }
  

  .col-12{
    margin-top:15px;
  }
  .content {
    padding: 40px;
    background: #fff;
    font-family: Cake sans;
    margin-top: -30px; /* Slightly adjusted to overlap with banner */
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: -1 ;
    text-align: justify; /* Justify content for better readability */
    line-height: 1.6; /* Adjusted line height for content */
  }
  

  .image{
    background-image: url('../../assets/img/add/new_bg_ai.png');
    background-repeat: no-repeat;
    background-size: cover;
   
  }
  .content h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .content h3 {
    font-size: 1.8rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #800080; /* Updated color for content h3 */
  }
  
  .content p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .content ul, .content ol {
    margin-left: 20px;
    margin-bottom: 20px;
    padding-left: 20px; /* Added padding for better alignment with text */
  }
  
  .content li {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    list-style-type: disc; /* Use bullets for unordered lists */
  }
  
  .content ol {
    list-style-type: decimal; /* Use numbers for ordered lists */
  }
  
  /* Hover effect on headings */
  h2:hover, h3:hover {
    color: #ff6f61;
    transition: color 0.3s ease;
  }
  
  
  
  #button {
    background-color:#7620ff;
    color: white;
    padding: 20px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 80%;
  }
  
  #button a {
    color: white;
    text-decoration: none;
    display: block;
    font-size: medium;
  }
  
  #button:hover {
    background-color: darkblue;
  }
  
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .banner-area1 {
      height: 50vh; /* Adjusted banner height for smaller screens */
    }
  
    .content {
      padding: 20px; /* Reduced padding for content on smaller screens */
      margin-top: -20px; /* Adjusted margin to overlap less with banner */
    }
  
    .banner-area1 h2 {
      font-size: 2.5rem; /* Adjusted font size for banner h2 on smaller screens */
    }
  
    .content h2 {
      font-size: 1.8rem; /* Adjusted font size for content h2 on smaller screens */
    }
  
    .content h3 {
      font-size: 1.5rem; /* Adjusted font size for content h3 on smaller screens */
    }
  
    .content p, .content li {
      font-size: 1rem; /* Adjusted font size for paragraphs and list items on smaller screens */
    }
  }
  
  @media (min-width: 1200px) {
    .banner-area1 {
      height: 70vh; /* Increased banner height for larger screens */
    }
  
    .content {
      padding: 60px; /* Increased padding for content on larger screens */
      margin-top: -60px; /* Adjusted margin to overlap more with banner */
    }
  
    .banner-area1 h2 {
      font-size: 4rem; /* Adjusted font size for banner h2 on larger screens */
    }
  
    .content h2 {
      font-size: 2.5rem; /* Adjusted font size for content h2 on larger screens */
    }
  
    .content h3 {
      font-size: 2rem; /* Adjusted font size for content h3 on larger screens */
    }
  
    .content p, .content li {
      font-size: 1.4rem; /* Adjusted font size for paragraphs and list items on larger screens */
    }
  }