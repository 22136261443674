/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Modal Container */
.modal-container {
  background: #fff;
  width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 12px;
  padding: 25px;
  position: relative;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-out;
  transform: translateY(0);
  animation: slideIn 0.3s ease-out;
}

/* Modal Animation */
@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #333;
}

/* Modal Body */
.modal-body {
  padding: 0 10px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  color: #333;
  background-color: #f9f9f9;
  transition: border 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #007bff;
  outline: none;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.add-item-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.add-item-btn:hover {
  background-color: #0056b3;
}

/* Quill Description Editor */
.description-editor {
  margin: 15px 0;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 10px;
  background-color: #fff;
}

.ql-toolbar {
  border-radius: 6px 6px 0 0;
  background-color: #f1f1f1;
  border: 1px solid #e0e0e0;
  margin-bottom: 0;
}

.ql-container {
  border-radius: 0 0 6px 6px;
  border: 1px solid #e0e0e0;
  border-top: none;
  font-size: 14px;
  min-height: 200px;
}

.ql-editor {
  line-height: 1.6;
  padding: 12px;
  font-family: 'Arial', sans-serif;
  background-color: #fff;
  border-radius: 6px;
}

.ql-editor::placeholder {
  color: #aaa;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal-container {
    width: 90vw;
    padding: 20px;
  }
}
