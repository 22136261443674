/* General styles */

.banner-area {
    margin-top: 60px;
    height: 70vh; /* Adjusted banner height for better proportion */
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
}

.banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.banner-area:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.banner-area h2 {
    font-size: 3rem;
    font-family: Cake sans;
    line-height: 1.2; /* Adjusted line height for better spacing */
    z-index: 2;
    color: #ff6f61; /* Updated color for banner h2 */
}

.img{
    background-size: cover;
    background-position: center;
    background-image: url('../../assets/img/add/new_bg_ai.png');
    padding: 40px 0px;;
}

.content {
    padding: 40px;
  
    font-family: Cake sans;
    margin-top: 0px; /* Slightly adjusted to overlap with banner */
    border-radius: 20px;
    margin-left: 30px;
    margin-right: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 0;
    text-align: justify; /* Justify content for better readability */
    line-height: 1.6; /* Adjusted line height for content */
   
}

.content h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.content h3 {
    font-size: 1.8rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #800080; /* Updated color for content h3 */
}

.content p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
}

.content ul, .content ol {
    margin-left: 20px;
    margin-bottom: 20px;
    padding-left: 20px; /* Added padding for better alignment with text */
}

.content li {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    list-style-type: disc; /* Use bullets for unordered lists */
}

.content ol {
    list-style-type: decimal; /* Use numbers for ordered lists */
}

/* Hover effect on headings */
h2:hover, h3:hover {
    color: #ff6f61;
    transition: color 0.3s ease;
}

/* Icon colors */
.fa-search {
    color: #e91e63; /* Pink for search icon */
}

.fa-clock {
    color: #03a9f4; /* Blue for clock icon */
}

.fa-chart-line {
    color: #4caf50; /* Green for chart line icon */
}

.fa-bell {
    color: #f44336; /* Red for bell icon */
}

.fa-file-alt {
    color: #9c27b0; /* Deep purple for file icon */
}

.fa-globe {
    color: #009688; /* Teal for globe icon */
}

.fa-tools {
    color: #ff9800; /* Orange for tools icon */
}

.fa-shield-alt, .fa-shield-alt-solid {
    color: #f44336; /* Red for shield icons */
}

.fa-user-cog, .fa-user-shield {
    color: #673ab7; /* Purple for user-related icons */
}

.fa-cogs {
    color: #795548; /* Brown for cogs icon */
}

.fa-laptop-code {
    color: #3f51b5; /* Indigo for laptop code icon */
}

.fa-mobile-alt {
    color: #ff5722; /* Deep orange for mobile alt icon */
}

.fa-shopping-cart {
    color: #00bcd4; /* Cyan for shopping cart icon */
}

.fa-rocket {
    color: #8bc34a; /* Light green for rocket icon */
}

.fa-tachometer-alt {
    color: #9e9e9e; /* Grey for tachometer icon */
}

.fa-lock {
    color: #607d8b; /* Grey for lock icon */
}

.fa-key {
    color: #9c27b0; /* Deep purple for key icon */
}

.fa-mask {
    color: #ff9800; /* Orange for mask icon */
}

.fa-cloud-upload-alt {
    color: #03a9f4; /* Blue for cloud upload icon */
}

.fa-check-circle {
    color: #4caf50; /* Green for check circle icon */
}

.fa-eye {
    color: #9e9e9e; /* Grey for eye icon */
}

.fa-phone-alt {
    color: #ff5722; /* Deep orange for phone alt icon */
}

/* Newly added icon colors */
.fa-lightbulb {
    color: #ffc107; /* Amber for lightbulb icon */
}

.fa-bullseye {
    color: #8bc34a; /* Light green for bullseye icon */
}

.fa-palette {
    color: #03a9f4; /* Blue for palette icon */
}

.fa-edit {
    color: #ff5722; /* Deep orange for edit icon */
}

.fa-users {
    color: #4caf50; /* Green for users icon */
}

.fa-laptop {
    color: #607d8b; /* Grey for laptop icon */
}

.fa-newspaper {
    color: #795548; /* Brown for newspaper icon */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .banner-area {
        height: 50vh; /* Adjusted banner height for smaller screens */
    }
    
    .content {
        padding: 20px; /* Reduced padding for content on smaller screens */
        margin-top: -20px; /* Adjusted margin to overlap less with banner */
    }

    .banner-area h2 {
        font-size: 2.5rem; /* Adjusted font size for banner h2 on smaller screens */
    }

    .content h2 {
        font-size: 1.8rem; /* Adjusted font size for content h2 on smaller screens */
    }

    .content h3 {
        font-size: 1.5rem; /* Adjusted font size for content h3 on smaller screens */
    }

    .content p, .content li {
        font-size: 1rem; /* Adjusted font size for paragraphs and list items on smaller screens */
    }
}