/* Large Header Section */
.large-header {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    z-index: 1;
}

/* Header and Canvas Animation */
.header-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
}

/* Large Header Section */
#large-header {
    position: relative;
    width: 100%;
    height: 100%;
}

/* Canvas Wrapper Styles */
.canvas-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start; /* Align content to the left */
    align-items: center; /* Center vertically */
    overflow: hidden;
}

/* Flex Center Class */
.flexCenter {
    display: flex;
    flex-direction: column; /* Align elements vertically */
    justify-content: flex-start; /* Align elements to the top */
    align-items: flex-start; /* Align elements to the left */
    text-align: left; /* Align text to the left */
    padding: 0 20px; /* Add some padding for better spacing */
    z-index: 2;
   margin-top: -40px;
    font-family: Cake sans;

}

/* Header Title Styles */
.head {
    font-size: 3rem; /* Adjust font size as needed */
    color: #fff;
    font-weight: bold;
    margin-top: -50px; /* Remove negative margin */
    padding: 0;
    margin-left: 70px;
    text-align: left; /* Align text to the left */
    z-index: 2;
}

.highlight{
    font-weight: bold;
    color:#ff5733;
}
/* Overlay Text Styles */
.overlay {
    position: absolute;
    top: 50%;
    margin-left:70px; /* Align to the left */
    transform: translateY(-50%); /* Center vertically */
    z-index: 2;
    margin-right: 10%;
    width: auto; /* Adjust width if needed */
}

.overlay-text {
    font-size: 2rem; /* Adjust font size as needed */
    color: #f8f9fa;
    font-family: Cake sans;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    text-align: left; /* Align text to the left */
}

/* Button Wrapper Styles */
.button-wrapper {
    margin-top: 200px;
    margin-left: 70px;
    z-index: 2;
}

/* Canvas Styles */
.canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Behind other elements */
}

/* Full Button Styles (Optional) */
.btn {
    padding: 10px 20px; /* Adjust button padding */
    background-color: #007bff; /* Button color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 1rem; /* Adjust font size as needed */
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0056b3; /* Darker color on hover */
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
    .head {
        font-size: 2.5rem;
        
    }

    .overlay-text {
        font-size: 1.75rem; /* Adjust for laptops */
    }
}

@media (max-width: 900px) {
    .head {
        font-size: 2rem;
    }

    .overlay-text {
        font-size: 1.5rem; 
        /* Adjust for tablets */
    }

    .button-wrapper {
        margin-top: 10.5rem;

        /* Adjust spacing for tablets */
    }
}

@media (max-width: 768px) {
    .head {
        font-size: 1.75rem;
    }

    .overlay-text {
        font-size: 1.25rem; 
        /* Adjust for smaller screens */
    }

    .button-wrapper {
        margin-left: 70px;
        margin-top: 90%; /* Adjust spacing for smaller screens */
    }
}

@media (max-width: 480px) {
    .head {
        font-size: 1.5rem; 
      margin-left:20px;
    }

    .overlay-text {
        font-size: 1rem; 
        margin-left:-50px;/* Adjust for very small screens */
    }

    .button-wrapper {
        margin-left: 20px;
        margin-top: 90%;  /* Adjust spacing for very small screens */
    }
}

@media (max-width: 400px) {
    
    .head {
        font-size: 1.25rem;
        margin-top:-50px ;
        
    }

    .overlay-text {
        font-size: 0.875rem;
        margin-bottom: 70px;
     
    }

    .button-wrapper {
        margin-top: 90%; 
        margin-left: 20px;
    }
}
